import React from 'react';
import { Spin } from 'antd';

const LoadingSpinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        zIndex: 99,
        backgroundColor: '#f0f2f5',
      }}
    >
      <Spin size='large' />
    </div>
  );
};

export default LoadingSpinner;
