import React from 'react';
import { Avatar, Button, Layout, Row, Space, Tag, Typography } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useNetwork } from 'ahooks';
import axios from 'axios';

const { Header } = Layout;
const { Text } = Typography;

const DashboardHeader = () => {
  const { user, logout } = useAuth0();
  const { online } = useNetwork();

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const resetTestData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/test-data/appointments/reset`);
  };

  return (
    <Header
      style={{ padding: 0, marginBottom: '1rem', backgroundColor: '#fff' }}
    >
      <Row
        align='middle'
        justify='space-between'
        style={{ width: '100%', height: '100%', padding: '0 1rem' }}
      >
        <Space>
          <Avatar src={user?.picture} />
          <Text>{user?.name}</Text>
          {online ? (
            <Tag color='success'>Online</Tag>
          ) : (
            <Tag color='error'>Offline</Tag>
          )}
          <Tag>
            <a
              href='https://stats.uptimerobot.com/2GK1wtVX2L'
              target='_blank'
              rel='noreferrer'
            >
              Covify-Status anzeigen
            </a>
          </Tag>
        </Space>
        {process.env.NODE_ENV === 'development' && (
          <Button onClick={resetTestData}>Reset test data</Button>
        )}
        <Button icon={<PoweroffOutlined />} danger onClick={handleLogout}>
          Abmelden
        </Button>
      </Row>
    </Header>
  );
};

export default DashboardHeader;
