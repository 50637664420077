import React, { useState } from 'react';
import { Button, DatePicker, Drawer, Form, Space, Image } from 'antd';
import { useToggle } from 'ahooks';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { orgaState } from 'store';
import { DATE_FORMAT } from 'COVIFY_CONSTANTS';
import { FileExcelOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import CsvSvg from 'assets/collecting_csv.svg';

type CsvValues = {
  fromTo: moment.Moment[];
};

const { RangePicker } = DatePicker;

const CsvExport = () => {
  const [downloadLink, setDownloadLink] = useState<string>();
  const [csvLoading, setCsvLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { id, name } = useRecoilValue(orgaState);
  const [visible, { toggle, set }] = useToggle(false);
  const [form] = Form.useForm();

  const handleFinish = async (values: CsvValues) => {
    const from = values?.fromTo[0].format(DATE_FORMAT);
    const to = values?.fromTo[1].format(DATE_FORMAT);
    setCsvLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documents/organizations/${id}/appointments/csv`,
        {
          params: { from, to },
          responseType: 'blob',
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const file = new Blob([data], { type: 'text/csv' });
      let url = window.URL.createObjectURL(file);
      setDownloadLink(url);
    } catch (error) {
      console.error(error);
    } finally {
      setCsvLoading(false);
    }
  };

  const onClose = () => {
    set(false);
    form.resetFields();
    setCsvLoading(false);
    setDownloadLink('');
  };

  return (
    <>
      <Button icon={<FileExcelOutlined />} onClick={() => set(true)}>
        CSV-Export
      </Button>
      <Drawer
        width='33vw'
        title='CSV Export anfordern'
        placement='right'
        onClose={onClose}
        visible={visible}
      >
        <Form
          form={form}
          name='csvExport'
          onFinish={handleFinish}
          requiredMark='optional'
          layout='vertical'
          scrollToFirstError
          initialValues={{
            from: moment(),
            to: moment(),
          }}
        >
          <Form.Item
            name='fromTo'
            label='Export Zeitraum'
            rules={[{ required: true }]}
          >
            <RangePicker
              format={DATE_FORMAT}
              style={{ width: '100%' }}
              ranges={{
                'Dieser Monat': [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
                'Letzter Monat': [
                  moment().subtract(1, 'months').startOf('month'),
                  moment().subtract(1, 'months').endOf('month'),
                ],
              }}
            />
          </Form.Item>
          <Button
            icon={<FileExcelOutlined />}
            type='primary'
            htmlType='submit'
            style={{ width: '100%' }}
            loading={csvLoading}
          >
            CSV-Datei erstellen
          </Button>
          {downloadLink && (
            <Space
              direction='vertical'
              style={{ textAlign: 'center', marginTop: '2rem' }}
            >
              <Image height='128px' preview={false} src={CsvSvg} />
              <Button
                type='link'
                href={downloadLink}
                download={`${name?.split(' ').join('')}_${moment(
                  form.getFieldValue('fromTo')[0]
                ).format(DATE_FORMAT)}_${moment(
                  form.getFieldValue('fromTo')[1]
                ).format(DATE_FORMAT)}.csv`}
              >
                {`${name?.split(' ').join('')}_${moment(
                  form.getFieldValue('fromTo')[0]
                ).format(DATE_FORMAT)}_${moment(
                  form.getFieldValue('fromTo')[1]
                ).format(DATE_FORMAT)}.csv`}
              </Button>
            </Space>
          )}
        </Form>
      </Drawer>
    </>
  );
};

export default CsvExport;
