import React from 'react';
import { Card, Statistic, Row, Col } from 'antd';
import LoadingSpinner from 'components/LoadingSpinner';
import { TestFeeStats } from './DashboardOverview';

type FeeStatsProps = {
  testType: string;
  data: TestFeeStats | undefined;
  loading: boolean;
};

export const FeeStats = ({ testType, data, loading }: FeeStatsProps) => {
  const sumValues = (obj: TestFeeStats) =>
    Object.values(obj).reduce((a, b) => a + b);

  if (!data) return <LoadingSpinner />;

  return (
    <Card title={`Abgeschlossene ${testType} (${sumValues(data)})`} loading={loading} style={{ marginBottom: '1rem' }}>
      <Row gutter={24}>
        <Col span={12}>
          <Statistic title='Kostenlos' value={data?.free} />
        </Col>
        <Col span={12}>
          <Statistic title='Kostenpflichtig' value={data?.paid} />
        </Col>
      </Row>
    </Card>
  );
};
