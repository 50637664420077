import React from "react";
import { Card, Statistic, Row, Col } from "antd";
import LoadingSpinner from "components/LoadingSpinner";
import { ResultsResponse } from "./DashboardOverview";

type ResultsProps = {
  data: ResultsResponse | undefined;
  loading: boolean;
};

export const Results = ({ data, loading }: ResultsProps) => {
  const sumValues = (obj: ResultsResponse) => Object.values(obj).reduce((a, b) => a + b);

  if (!data) return <LoadingSpinner />;

  return (
    <Card title={`Ergebnisübersicht (${sumValues(data)})`} loading={loading} style={{ marginBottom: '1rem' }}>
      <Row gutter={24}>
        <Col span={8}>
          <Statistic title="Positiv" value={data?.POSITIVE} />
        </Col>
        <Col span={8}>
          <Statistic title="Negativ" value={data?.NEGATIVE} />
        </Col>
        <Col span={8}>
          <Statistic title="Ungültig" value={data?.INVALID} />
        </Col>
      </Row>
    </Card>
  );
};

