import { useState } from 'react';
import {
  Table,
  Card,
  Button,
  Typography,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Row,
  Space,
  DatePicker,
  Checkbox,
  Collapse,
  Form,
  Radio,
  message,
  Alert,
} from 'antd';
import {
  AppointmentDTO,
  PageAppointmentDTO,
  CreateTestResultRequest,
} from 'types';
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { orgaState } from 'store';
import { FilePdfOutlined, SyncOutlined, MailOutlined } from '@ant-design/icons';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { getResultColor } from 'helpers';
import { useToggle } from 'ahooks';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { DATE_FORMAT, RESULT_TYPE_ARRAY } from 'COVIFY_CONSTANTS';
import { FilterValue } from 'antd/lib/table/interface';
import CsvExport from './CsvExportDrawer';
import ActivityLog from 'components/shared/ActivityLog';
import Search from 'antd/lib/input/Search';

const { Text, Title } = Typography;
const { Panel } = Collapse;

const DashboardTests = () => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 20,
    hideOnSinglePage: true,
  });
  const [sortState, setSortState] = useState<'asc' | 'desc'>('desc');
  const [queryState, setQueryState] = useState<string>('');
  const [pdfLang, setPdfLang] = useState<'DE' | 'EN'>('DE');
  const [selectedTest, setTest] = useState<AppointmentDTO>();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [statusFilters, setStatusFilters] = useState<FilterValue>([
    'CHECKIN',
    'CLOSED',
  ]);
  const [checked, { toggle: checker, set: setChecker }] = useToggle(false);
  const [pdfIsLoading, setPdfLoading] = useState(false);
  const [mailIsSending, setMailIsSending] = useState(false);
  const orgaDetails = useRecoilValue(orgaState);
  const [visible, { toggle, set }] = useToggle(false);
  const { getAccessTokenSilently } = useAuth0();
  const [form] = Form.useForm();

  const { isLoading, data, refetch, isFetching } = useQuery<PageAppointmentDTO>(
    `appointments/organizations/${orgaDetails?.id}?date=${selectedDate.format(
      DATE_FORMAT
    )}&status=${statusFilters.join(',')}&sort=startTime,${sortState}&page=${
      pagination.current && pagination?.current - 1
    }&size=${pagination.pageSize}${queryState && `&query=${queryState}`}`,
    {
      enabled: orgaDetails?.id !== undefined,
      onSuccess: (data) =>
        setPagination({ ...pagination, total: data?.totalElements }),
    }
  );

  const mutation = useMutation(async (result: CreateTestResultRequest) => {
    const token = await getAccessTokenSilently();
    return axios.put(
      `${process.env.REACT_APP_API_URL}/appointments/${selectedTest?.id}/result`,
      result,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  });

  const handleSearch = (query: string) => {
    setQueryState(query);
    setPagination({ ...pagination, current: 1 });
    refetch();
  };

  const handleDateChange = (
    value: moment.Moment | null,
    dateString: string
  ) => {
    value && setSelectedDate(value);
  };

  const handleClick = (key: AppointmentDTO['id']) => {
    const filterSelected = data?.content?.filter((value) => value?.id === key);
    filterSelected && setTest(filterSelected[0]);
    set(true);
  };

  const onClose = () => {
    set(false);
    form.resetFields();
    setChecker(false);
  };

  const handleSendMailClick = async () => {
    setMailIsSending(true);
    const token = await getAccessTokenSilently();
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/documents/appointments/${selectedTest?.id}/result/mail`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        message.success('Testergebnis wurde versendet.');
      })
      .catch((err) => {
        message.error('Testergebnis konnte nicht versendet werden.');
      })
      .finally(() => {
        setMailIsSending(false);
      });
  };

  const handlePdfClick = async () => {
    setPdfLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documents/appointments/${selectedTest?.id}/result/pdf?lang=${pdfLang}`,
        { responseType: 'blob', headers: { Authorization: `Bearer ${token}` } }
      );
      const file = new Blob([data], { type: 'application/pdf' });
      let url = window.URL.createObjectURL(file);
      window.open(url);
    } catch (error) {
      console.error(error);
    } finally {
      setPdfLoading(false);
    }
  };

  const handleTestResultSubmit = (values: CreateTestResultRequest) => {
    mutation.mutate(values, {
      onSuccess: () => {
        refetch();
        onClose();
        message.success('Testergebnis wurde erfolgreich angelegt.');
      },
      onError: () => {
        message.error(
          'Fehler beim Anlegen, bitte vesuchen Sie es noch einmal.'
        );
      },
    });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    setPagination({ ...pagination, current: pagination.current });
    if (sorter.order === 'ascend') {
      setSortState('asc');
    } else if (sorter.order === 'descend') {
      setSortState('desc');
    }
    if (filters['testResult.result'] === null) {
      setStatusFilters(['CHECKIN', 'CLOSED']);
    } else {
      setStatusFilters(filters['testResult.result']);
    }
    refetch();
  };

  const columns: ColumnsType<AppointmentDTO> = [
    {
      title: 'Datum (Termin)',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: AppointmentDTO) => (
        <Text>
          {record?.date} - {record?.startTime}
        </Text>
      ),
    },
    {
      title: 'Name',
      render: (record: AppointmentDTO) => (
        <Text>
          {record?.proband?.firstname} {record?.proband?.lastname}
        </Text>
      ),
    },
    {
      title: 'Test',
      render: (record: AppointmentDTO) => (
        <Text>
          {record?.testType?.name} (
          {record?.testType?.price === 0
            ? 'Kostenlos'
            : `${record?.testType?.price?.toFixed(2)} €`}
          )
        </Text>
      ),
    },
    {
      title: 'Gespeichert am',
      dataIndex: ['testResult', 'createdDate'],
      render: (createdDate) => <Text>{createdDate ?? 'In Bearbeitung'}</Text>,
    },
    {
      title: 'Ergebnis',
      dataIndex: ['testResult', 'result'],
      filteredValue: statusFilters,
      filters: [
        {
          text: 'In Bearbeitung',
          value: 'CHECKIN',
        },
        {
          text: 'Abgeschlossen',
          value: 'CLOSED',
        },
      ],
      render: (result) => (
        <Text className={getResultColor(result)}>
          {result ?? 'In Bearbeitung'}
        </Text>
      ),
    },
  ];

  return (
    <>
      <Card
        title='Tests'
        extra={
          <Space>
            <Search
              placeholder='Nach Namen suchen'
              onSearch={handleSearch}
              loading={isLoading}
              enterButton
              allowClear
            />
            <DatePicker
              format={DATE_FORMAT}
              value={selectedDate}
              onChange={handleDateChange}
            />
            <Button
              loading={isFetching}
              onClick={() => refetch()}
              type='default'
              icon={<SyncOutlined />}
            />
            <CsvExport />
          </Space>
        }
      >
        <Table<AppointmentDTO>
          columns={columns}
          dataSource={data?.content}
          onChange={handleTableChange}
          pagination={pagination}
          loading={isLoading}
          rowKey='id'
          rowClassName='row-pointer'
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: () => handleClick(record.id),
            };
          }}
        />
      </Card>
      <Drawer
        title={`Test ${selectedTest?.testResult?.id ?? ''}`}
        width='50vw'
        placement='right'
        onClose={onClose}
        visible={visible}
        bodyStyle={{ marginBottom: '2rem' }}
      >
        <Col>
          <Row>
            <Descriptions
              style={{ width: '100%' }}
              title='Informationen'
              bordered
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label='Test-ID'>
                {selectedTest?.testResult?.id}
              </Descriptions.Item>
              <Descriptions.Item label='Termin-ID'>
                {selectedTest?.id}
              </Descriptions.Item>
              <Descriptions.Item label='Datum (Termin)'>
                {selectedTest?.date}
              </Descriptions.Item>
              <Descriptions.Item label='Testdurchführung'>
                {selectedTest?.testResult?.createdDate ?? 'In Bearbeitung'}
              </Descriptions.Item>
              <Descriptions.Item label='Test'>
                {selectedTest?.testType?.name} (
                {selectedTest?.testType?.price === 0
                  ? 'Kostenlos'
                  : `${selectedTest?.testType?.price?.toFixed(2)} €`}
                )
              </Descriptions.Item>
              <Descriptions.Item label='Personengruppe'>
                {selectedTest?.reason}
              </Descriptions.Item>
              <Descriptions.Item label='Name'>
                {selectedTest?.proband?.firstname}{' '}
                {selectedTest?.proband?.lastname}
              </Descriptions.Item>
              <Descriptions.Item label='Geburtstag'>
                {selectedTest?.proband?.birthDate}
              </Descriptions.Item>
              <Descriptions.Item label='Adresse'>
                {selectedTest?.proband?.street}{' '}
                {selectedTest?.proband?.houseNumber},{' '}
                {selectedTest?.proband?.zip} {selectedTest?.proband?.city}
              </Descriptions.Item>
              <Descriptions.Item label='E-Mail'>
                {selectedTest?.proband?.email}
              </Descriptions.Item>
              <Descriptions.Item label='Telefon'>
                {selectedTest?.proband?.phone || (
                  <Text type='secondary'>Keine Angabe</Text>
                )}
              </Descriptions.Item>
              <Descriptions.Item label='Corona-Warn-App'>
                {selectedTest?.hasCwa ? 'Aktiviert' : 'Deaktiviert'}
              </Descriptions.Item>
            </Descriptions>
          </Row>
          <Divider orientation='center'>Ergebnis</Divider>
          <>
            {selectedTest?.testResult?.result ? (
              <Row
                justify='center'
                style={{ width: '100%', textAlign: 'center' }}
              >
                <Title
                  level={4}
                  className={getResultColor(selectedTest?.testResult?.result)}
                >
                  {selectedTest?.testResult?.result}
                </Title>
              </Row>
            ) : (
              <Collapse style={{ width: '100%', marginTop: '1rem' }}>
                <Panel header='Testergebnis hinzufügen' key='1'>
                  <Form
                    name='result'
                    form={form}
                    onFinish={handleTestResultSubmit}
                    initialValues={{
                      sendMail: false,
                    }}
                  >
                    <Space direction='vertical'>
                      <Typography.Text>
                        Die Person wurde getestet auf eine COVID-19 Infektion
                        und das Testergebnis ist:
                      </Typography.Text>
                      <Form.Item
                        name='result'
                        rules={[
                          {
                            required: true,
                            message: 'Bitte ein Testergebnis auswählen.',
                          },
                        ]}
                      >
                        <Radio.Group buttonStyle='solid'>
                          <Space direction='horizontal'>
                            {RESULT_TYPE_ARRAY.map((type) => (
                              <Radio.Button key={type} value={type}>
                                <Text strong>{type}</Text>
                              </Radio.Button>
                            ))}
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                      <Text>
                        Mit dem Anlegen des Testergebnisses wird das Ergebnis
                        gespeichert und die Person kann per E-Mail
                        benachrichtigt werden.
                      </Text>
                      <Text>
                        Bitte überprüfen Sie das Ergebnis und stellen Sie
                        sicher, dass das Ergebnis mit der getesteten Person
                        übereinstimmt.
                      </Text>
                      <Checkbox checked={checked} onChange={() => checker()}>
                        Ja, alle Angaben sind korrekt.
                      </Checkbox>
                      <Form.Item name='sendMail' valuePropName='checked'>
                        <Checkbox>Ergebnis per E-Mail versenden</Checkbox>
                      </Form.Item>
                      {selectedTest?.hasCwa && (
                        <Alert
                          type='warning'
                          showIcon
                          message='Der Proband hat die CWA Übertragung aktiviert.
                         Fall Sie dieses Feld deaktivieren, kann der
                         Proband das Ergebnis nicht in die CWA einpflegen.'
                        />
                      )}
                      <Form.Item style={{ textAlign: 'center' }}>
                        <Button
                          type='primary'
                          htmlType='submit'
                          disabled={!checked}
                          loading={mutation?.isLoading}
                        >
                          Testergebnis speichern
                        </Button>
                      </Form.Item>
                    </Space>
                  </Form>
                </Panel>
              </Collapse>
            )}
          </>
          <Divider orientation='center'>Anhang</Divider>
          <Row justify='center' style={{ width: '100%' }}>
            {selectedTest?.testResult ? (
              <Space direction='vertical' align='center'>
                <Radio.Group value={pdfLang} buttonStyle='solid'>
                  <Radio.Button value='DE' onClick={() => setPdfLang('DE')}>
                    Deutsch
                  </Radio.Button>
                  <Radio.Button value='EN' onClick={() => setPdfLang('EN')}>
                    Englisch
                  </Radio.Button>
                </Radio.Group>
                <Button
                  loading={pdfIsLoading}
                  disabled={pdfIsLoading}
                  icon={<FilePdfOutlined />}
                  onClick={handlePdfClick}
                >
                  Testergebnis anzeigen
                </Button>

                <Button
                  loading={mailIsSending}
                  disabled={mailIsSending}
                  icon={<MailOutlined />}
                  onClick={handleSendMailClick}
                >
                  Erneut versenden
                </Button>
              </Space>
            ) : (
              <Text type='secondary'>
                Hier werden die Dokumente zum abgeschlossenen Test angezeigt.
              </Text>
            )}
          </Row>
          <Divider orientation='center'>Aktivitäten</Divider>
          <Row justify='center' style={{ width: '100%' }}>
            <ActivityLog activities={selectedTest?.activities} />
          </Row>
        </Col>
      </Drawer>
    </>
  );
};

export default DashboardTests;
