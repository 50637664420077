import React from 'react';
import { Col, Row, Typography } from 'antd';
import { Total } from './Total';
import Upcoming from './Upcoming';
import { DATE_FORMAT } from 'COVIFY_CONSTANTS';
import moment from 'moment';
import { Results } from './Results';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { orgaState } from 'store';
import { TestTypes } from './TestTypes';
import { AppointmentTypes } from './AppointmentTypes';
import { Forecast } from './Forecast';
import { FeeStats } from './FeeStats';

const { Title } = Typography;

export type TotalResponse = {
  OPEN: number;
  CLOSED: number;
  CANCELED: number;
  CHECKIN: number;
};

export type ResultsResponse = {
  INVALID: number;
  NEGATIVE: number;
  OPEN: number;
  POSITIVE: number;
};

export type AppointmentTypeResponse = {
  BOOKING: number;
  INSTANT: number;
  MANUAL: number;
};

export type TestTypeResponse = {
  PCR: number;
  SCHNELLTEST: number;
};

export type TestFeeStats = {
  free: number;
  paid: number;
};

type StatsResponse = {
  total: TotalResponse;
  results: ResultsResponse;
  testTypes: TestTypeResponse;
  appointmentTypes: AppointmentTypeResponse;
  pocFeeStats: TestFeeStats;
  pcrFeeStats: TestFeeStats;
};

const DashboardOverview = () => {
  const { id } = useRecoilValue(orgaState);
  const { data, isLoading } = useQuery<StatsResponse>(
    `appointments/organizations/${id}/total`,
    { enabled: id !== undefined }
  );
  return (
    <Col span={24}>
      <Row>
        <Title level={2}>
          Ihre Übersicht für den {moment().format(DATE_FORMAT)}
        </Title>
      </Row>
      <Row gutter={24} style={{ marginBottom: '1rem' }}>
        <Col span={12}>
          <Upcoming />
          <Forecast />
        </Col>
        <Col span={12}>
          <Row gutter={24}>
            <Col span={24}>
              <Total data={data?.total} loading={isLoading} />
            </Col>
            <Col span={24}>
              <Results data={data?.results} loading={isLoading} />
            </Col>
            <Col span={12}>
              <TestTypes data={data?.testTypes} loading={isLoading} />
            </Col>
            <Col span={12}>
              <AppointmentTypes
                data={data?.appointmentTypes}
                loading={isLoading}
              />
            </Col>
            <Col span={12}>
              <FeeStats testType={'Schnelltests'} data={data?.pocFeeStats} loading={isLoading} />
            </Col>
            <Col span={12}>
              <FeeStats testType={'PCR-Tests'} data={data?.pcrFeeStats} loading={isLoading} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default DashboardOverview;
