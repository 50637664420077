import React from 'react';
import { Timeline, Typography } from 'antd';
import { ActivityDTO } from 'types';

type ActivityLogProps = {
  activities: ActivityDTO[] | undefined;
};

const ActivityLog = ({ activities }: ActivityLogProps) => {
  return (
    <Timeline reverse={true}>
      {activities?.map((entry, index) => {
        return (
          <Timeline.Item key={entry.id} color={entry.color}>
            <Typography>{entry.timestamp}</Typography>
            <Typography>{entry.description}</Typography>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

export default ActivityLog;
