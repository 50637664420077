import { useToggle } from 'ahooks';
import { Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { TestTypeDTO } from 'types';
import { RefetchSettingsProps, SettingsProps } from 'types/customTypes';
import NewTestTypeDrawer from './NewTestTypeDrawer';
import TestTypeUpdateDrawer from './TestTypeUpdateDrawer';

const TestTypeSettings = ({
  settings,
  refetch,
}: SettingsProps & RefetchSettingsProps) => {
  const [selectedTestType, setTestType] = useState<TestTypeDTO>();
  const [visible, { toggle, set }] = useToggle(false);

  const handleClick = (record: TestTypeDTO) => {
    setTestType(record);
    set(true);
  };

  const columns: ColumnsType<TestTypeDTO> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Hersteller',
      dataIndex: 'manufacturer',
    },
    {
      title: 'Preis',
      dataIndex: 'price',
    },
    {
      title: 'Beschreibung',
      dataIndex: 'description',
    },
  ];

  return (
    <>
      <Row justify='end' style={{ width: '100%', marginBottom: '1rem' }}>
        <NewTestTypeDrawer refetch={refetch} />
      </Row>
      <Table<TestTypeDTO>
        columns={columns}
        loading={!settings}
        dataSource={settings?.tests}
        rowClassName='row-pointer'
        rowKey='id'
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: () => handleClick(record),
          };
        }}
      />
      <TestTypeUpdateDrawer
        settings={settings}
        data={selectedTestType}
        visible={visible}
        toggle={toggle}
        refetch={refetch}
      />
    </>
  );
};

export default TestTypeSettings;
