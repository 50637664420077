/* eslint-disable array-callback-return */
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  message,
  Row,
  Space,
  TimePicker,
} from 'antd';
import axios from 'axios';
import { TIME_FORMAT, WEEKDAYS } from 'COVIFY_CONSTANTS';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import { orgaState } from 'store';
import { BusinessHourDTO } from 'types';
import ld from 'lodash';
import { RefetchSettingsProps, SettingsProps } from 'types/customTypes';
import { dayOfWeek } from "../../helpers";

const { REACT_APP_API_URL } = process.env;

const BusinessHoursSettings = ({
  settings,
  refetch,
}: SettingsProps & RefetchSettingsProps) => {
  const [form] = Form.useForm();
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useRecoilValue(orgaState);

  useEffect(() => {
    const now = moment();
    if (settings?.businessHours) {
      settings?.businessHours?.map((day) => {
        switch (day?.day) {
          case 0:
            form.setFieldsValue({
              monday: {
                openActive: day?.openActive,
                breakActive: day?.breakActive,
                openingTime: moment(day?.openingTime, TIME_FORMAT),
                closingTime: moment(day?.closingTime, TIME_FORMAT),
                breakStartTime: moment(day?.breakStartTime, TIME_FORMAT),
                breakEndTime: moment(day?.breakEndTime, TIME_FORMAT),
              },
            });
            break;
          case 1:
            form.setFieldsValue({
              tuesday: {
                openActive: day?.openActive,
                breakActive: day?.breakActive,
                openingTime: moment(day?.openingTime, TIME_FORMAT),
                closingTime: moment(day?.closingTime, TIME_FORMAT),
                breakStartTime: moment(day?.breakStartTime, TIME_FORMAT),
                breakEndTime: moment(day?.breakEndTime, TIME_FORMAT),
              },
            });
            break;
          case 2:
            form.setFieldsValue({
              wednesday: {
                openActive: day?.openActive,
                breakActive: day?.breakActive,
                openingTime: moment(day?.openingTime, TIME_FORMAT),
                closingTime: moment(day?.closingTime, TIME_FORMAT),
                breakStartTime: moment(day?.breakStartTime, TIME_FORMAT),
                breakEndTime: moment(day?.breakEndTime, TIME_FORMAT),
              },
            });
            break;
          case 3:
            form.setFieldsValue({
              thursday: {
                openActive: day?.openActive,
                breakActive: day?.breakActive,
                openingTime: moment(day?.openingTime, TIME_FORMAT),
                closingTime: moment(day?.closingTime, TIME_FORMAT),
                breakStartTime: moment(day?.breakStartTime, TIME_FORMAT),
                breakEndTime: moment(day?.breakEndTime, TIME_FORMAT),
              },
            });
            break;
          case 4:
            form.setFieldsValue({
              friday: {
                openActive: day?.openActive,
                breakActive: day?.breakActive,
                openingTime: moment(day?.openingTime, TIME_FORMAT),
                closingTime: moment(day?.closingTime, TIME_FORMAT),
                breakStartTime: moment(day?.breakStartTime, TIME_FORMAT),
                breakEndTime: moment(day?.breakEndTime, TIME_FORMAT),
              },
            });
            break;
          case 5:
            form.setFieldsValue({
              saturday: {
                openActive: day?.openActive,
                breakActive: day?.breakActive,
                openingTime: moment(day?.openingTime, TIME_FORMAT),
                closingTime: moment(day?.closingTime, TIME_FORMAT),
                breakStartTime: moment(day?.breakStartTime, TIME_FORMAT),
                breakEndTime: moment(day?.breakEndTime, TIME_FORMAT),
              },
            });
            break;
          case 6:
            form.setFieldsValue({
              sunday: {
                openActive: day?.openActive,
                breakActive: day?.breakActive,
                openingTime: moment(day?.openingTime, TIME_FORMAT),
                closingTime: moment(day?.closingTime, TIME_FORMAT),
                breakStartTime: moment(day?.breakStartTime, TIME_FORMAT),
                breakEndTime: moment(day?.breakEndTime, TIME_FORMAT),
              },
            });
            break;
          default:
            break;
        }
      });
    } else {
      form.setFieldsValue({
        monday: {
          openActive: false,
          breakActive: false,
          openingTime: now,
          closingTime: now,
          breakStartTime: now,
          breakEndTime: now,
        },
      });
      form.setFieldsValue({
        tuesday: {
          openActive: false,
          breakActive: false,
          openingTime: now,
          closingTime: now,
          breakStartTime: now,
          breakEndTime: now,
        },
      });
      form.setFieldsValue({
        wednesday: {
          openActive: false,
          breakActive: false,
          openingTime: now,
          closingTime: now,
          breakStartTime: now,
          breakEndTime: now,
        },
      });
      form.setFieldsValue({
        thursday: {
          openActive: false,
          breakActive: false,
          openingTime: now,
          closingTime: now,
          breakStartTime: now,
          breakEndTime: now,
        },
      });
      form.setFieldsValue({
        friday: {
          openActive: false,
          breakActive: false,
          openingTime: now,
          closingTime: now,
          breakStartTime: now,
          breakEndTime: now,
        },
      });
      form.setFieldsValue({
        saturday: {
          openActive: false,
          breakActive: false,
          openingTime: now,
          closingTime: now,
          breakStartTime: now,
          breakEndTime: now,
        },
      });
      form.setFieldsValue({
        sunday: {
          openActive: false,
          breakActive: false,
          openingTime: now,
          closingTime: now,
          breakStartTime: now,
          breakEndTime: now,
        },
      });
    }
  });

  const putBusinessHours = useMutation(
    async (values: any) => {
      const token = await getAccessTokenSilently();
      return axios.put(
        `${REACT_APP_API_URL}/organizations/${id}/settings/businessHours`,
        values,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    },
    {
      onSuccess: () => {
        refetch();
        message.success('Öffnungszeiten gespeichert.');
      },
    }
  );

  const onFinish = (values: BusinessHourDTO[]) => {
    const tmp = ld.cloneDeep(values);
    Object.values(tmp).map((day) => {
      day.openingTime = moment(day.openingTime).format(TIME_FORMAT);
      day.closingTime = moment(day.closingTime).format(TIME_FORMAT);
      day.breakStartTime = moment(day.breakStartTime).format(TIME_FORMAT);
      day.breakEndTime = moment(day.breakEndTime).format(TIME_FORMAT);
    });
    putBusinessHours.mutate(Object.values(tmp));
  };

  return (
    <Form
      form={form}
      name='businessHours'
      layout='vertical'
      onFinish={onFinish}>
      <Space direction='vertical'>
        {WEEKDAYS.map((day, i) => (
          <Form.Item key={i} name={day.toLowerCase()}>
            <Row gutter={16}>
              <Col className='gutter-row' span={4}>
                <Space direction='horizontal' align='center'>
                  <Form.Item
                    name={[day.toLowerCase(), 'openActive']}
                    valuePropName='checked'>
                    <Checkbox />
                  </Form.Item>
                  <Form.Item>{dayOfWeek[i]}</Form.Item>
                </Space>
              </Col>
              <Col className='gutter-row' span={8}>
                <Space direction='horizontal' align='center'>
                  <Form.Item name={[day.toLowerCase(), 'openingTime']}>
                    <TimePicker format={TIME_FORMAT} />
                  </Form.Item>
                  <Form.Item>bis</Form.Item>
                  <Form.Item name={[day.toLowerCase(), 'closingTime']}>
                    <TimePicker format={TIME_FORMAT} />
                  </Form.Item>
                </Space>
              </Col>
              <Col className='gutter-row' span={3}>
                <Space direction='horizontal' align='center'>
                  <Form.Item>Pause?</Form.Item>
                  <Form.Item
                    name={[day.toLowerCase(), 'breakActive']}
                    valuePropName='checked'>
                    <Checkbox />
                  </Form.Item>
                </Space>
              </Col>

              <Col className='gutter-row' span={8}>
                <Space direction='horizontal' align='center'>
                  <Form.Item name={[day.toLowerCase(), 'breakStartTime']}>
                    <TimePicker format={TIME_FORMAT} />
                  </Form.Item>
                  <Form.Item>bis</Form.Item>
                  <Form.Item name={[day.toLowerCase(), 'breakEndTime']}>
                    <TimePicker format={TIME_FORMAT} />
                  </Form.Item>
                </Space>
              </Col>
            </Row>

            <Form.Item
              name={[day.toLowerCase(), 'day']}
              hidden
              initialValue={i}>
              <InputNumber />
            </Form.Item>
            <Form.Item name={[day.toLowerCase(), 'id']} hidden initialValue=''>
              <InputNumber />
            </Form.Item>
          </Form.Item>
        ))}
        <Button type='primary' htmlType='submit'>
          Speichern
        </Button>
      </Space>
    </Form>
  );
};

export default BusinessHoursSettings;
