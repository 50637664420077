import React from "react";
import { Card, Statistic, Row, Col } from "antd";
import LoadingSpinner from "components/LoadingSpinner";
import { AppointmentTypeResponse } from "./DashboardOverview";

type AppointmentTypesProps = {
  data: AppointmentTypeResponse | undefined;
  loading: boolean;
};

export const AppointmentTypes = ({ data, loading }: AppointmentTypesProps) => {
  if (!data) return <LoadingSpinner />;

  return (
    <Card title={`Gebuchte Termintypen`} loading={loading} style={{ marginBottom: '1rem' }}>
      <Row gutter={24}>
        <Col span={8}>
          <Statistic title="Terminbuchung" value={data?.BOOKING} />
        </Col>
        <Col span={8}>
          <Statistic title="Sofortbuchung" value={data?.INSTANT} />
        </Col>
        <Col span={8}>
          <Statistic title="Manuell" value={data?.MANUAL} />
        </Col>
      </Row>
    </Card>
  );
};

