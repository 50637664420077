import { Card, List } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { orgaState } from 'store';
import { AppointmentDTO } from 'types';

const Upcoming = () => {
  const { id } = useRecoilValue(orgaState);
  const { data, isLoading } = useQuery<AppointmentDTO[]>(
    `appointments/organizations/${id}/upcoming`,
    { enabled: id !== undefined }
  );
  return (
    <Card
      title='Anstehende Termine'
      loading={isLoading}
      extra={<Link to='/appointments'>Alle Termine anzeigen</Link>}
      style={{ marginBottom: '1rem' }}
    >
      <List
        size='large'
        bordered
        loading={isLoading}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            {item?.proband?.firstname} {item?.proband?.lastname} um&nbsp;
            {item?.startTime} Uhr - {item?.testType?.name}
          </List.Item>
        )}
      />
    </Card>
  );
};

export default Upcoming;
