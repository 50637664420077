import React, { useState } from 'react';
import { Button, Descriptions, Divider, message, Row, Typography } from 'antd';
import { AppointmentDTO, PageAppointmentDTO } from 'types';
import { FilePdfOutlined } from '@ant-design/icons';
import axios from 'axios';
import { QueryObserverResult, RefetchOptions, useMutation } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import ActivityLog from 'components/shared/ActivityLog';

type ViewProps = {
  selectedAppointment: AppointmentDTO | undefined;
  onClose: () => void;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<PageAppointmentDTO, unknown>>;
};

const { REACT_APP_API_URL } = process.env;

const { Text } = Typography;

const ViewAppointment = ({
  selectedAppointment,
  refetch,
  onClose,
}: ViewProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [pdfIsLoading, setPdfLoading] = useState(false);

  const cancelAppointment = useMutation(
    async () => {
      const token = await getAccessTokenSilently();
      return axios.patch(
        `${REACT_APP_API_URL}/appointments/${selectedAppointment?.id}/cancel`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
    },
    {
      onSuccess: () => {
        message.success('Termin wurde erfolgreich storniert.');
        refetch();
        onClose();
      },
      onError: () => {
        message.error(
          'Termin konnte nicht storniert werden, bitte versuchen Sie es noch einmal.'
        );
      },
    }
  );

  const handlePdfClick = async () => {
    setPdfLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/documents/appointments/${selectedAppointment?.id}/registration/pdf`,
        { responseType: 'blob', headers: { Authorization: `Bearer ${token}` } }
      );
      const file = new Blob([data], { type: 'application/pdf' });
      let url = window.URL.createObjectURL(file);
      window.open(url);
    } catch (error) {
      console.error(error);
    } finally {
      setPdfLoading(false);
    }
  };

  const handleDeleteAppointment = () => {
    cancelAppointment.mutate();
  };

  return (
    <>
      <Divider type='horizontal' orientation='center'>
        Termindaten
      </Divider>
      <Descriptions
        style={{ width: '100%' }}
        bordered
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
        <Descriptions.Item label='Termin-ID'>
          {selectedAppointment?.id}
        </Descriptions.Item>
        <Descriptions.Item label='Datum'>
          {selectedAppointment?.date}
        </Descriptions.Item>
        <Descriptions.Item label='Uhrzeit'>
          {selectedAppointment?.startTime} Uhr - {selectedAppointment?.endTime}{' '}
          Uhr
        </Descriptions.Item>
        <Descriptions.Item label='Corona Test'>
          {selectedAppointment?.testType?.name} (
          {selectedAppointment?.testType?.price === 0
            ? 'Kostenlos'
            : `${selectedAppointment?.testType?.price?.toFixed(2)} €`}
          )
        </Descriptions.Item>
        <Descriptions.Item label='Personengruppe'>
          {selectedAppointment?.reason}
        </Descriptions.Item>
        <Descriptions.Item label='Name'>
          {selectedAppointment?.proband?.firstname}{' '}
          {selectedAppointment?.proband?.lastname}
        </Descriptions.Item>
        <Descriptions.Item label='Geburtstag'>
          {selectedAppointment?.proband?.birthDate}
        </Descriptions.Item>
        <Descriptions.Item label='Adresse'>
          {selectedAppointment?.proband?.street}{' '}
          {selectedAppointment?.proband?.houseNumber},{' '}
          {selectedAppointment?.proband?.zip}{' '}
          {selectedAppointment?.proband?.city}
        </Descriptions.Item>
        <Descriptions.Item label='E-Mail'>
          {selectedAppointment?.proband?.email}
        </Descriptions.Item>
        <Descriptions.Item label='Telefon'>
          {selectedAppointment?.proband?.phone || (
            <Text type='secondary'>Keine Angabe</Text>
          )}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation='center'>Anhang</Divider>
      <Row justify='center' style={{ width: '100%' }}>
        <Button
          loading={pdfIsLoading}
          disabled={pdfIsLoading || selectedAppointment?.status === 'CANCELED'}
          icon={<FilePdfOutlined />}
          onClick={handlePdfClick}>
          Einwilligungserklärung anzeigen
        </Button>
      </Row>

      <Divider orientation='center'>Aktivitäten</Divider>
      <Row justify='center' style={{ width: '100%' }}>
        <ActivityLog activities={selectedAppointment?.activities} />
      </Row>
      {selectedAppointment?.status === 'OPEN' && (
        <>
          <Divider orientation='center'>Achtung</Divider>
          <Row justify='center' style={{ width: '100%' }}>
            <Button
              onClick={handleDeleteAppointment}
              danger
              loading={cancelAppointment.isLoading}
              disabled={cancelAppointment.isLoading}>
              Termin stornieren
            </Button>
          </Row>
        </>
      )}
    </>
  );
};

export default ViewAppointment;
