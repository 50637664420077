import { RefetchOptions, QueryObserverResult } from 'react-query';
import { OrganizationSettingsDTO } from 'types';

export enum TestResult {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Open = 'OPEN',
}

export enum AppointmentStatus {
  OPEN = 'OPEN',
  CHECKIN = 'CHECKIN',
  CLOSED = 'CLOSED',
  CANCELED = 'CANCELED'
}

export type SettingsProps = {
  settings: OrganizationSettingsDTO | undefined;
};

export type RefetchSettingsProps = {
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<OrganizationSettingsDTO, unknown>>;
};
