import { atom } from 'recoil';
import { OrganizationDTO, UserDetailsResponse } from 'types';

export const userState = atom<UserDetailsResponse>({
  key: 'userState',
  default: {
    userId: '',
    organizationId: '',
  },
});

export const orgaState = atom<OrganizationDTO>({
  key: 'orgaState',
  default: {},
});
