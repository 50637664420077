import React from 'react';
import { Card, Statistic, Row, Col } from 'antd';
import LoadingSpinner from 'components/LoadingSpinner';
import { TotalResponse } from './DashboardOverview';

type TotalProps = {
  data: TotalResponse | undefined;
  loading: boolean;
};

export const Total = ({ data, loading }: TotalProps) => {
  const sumValues = (obj: TotalResponse) =>
    Object.values(obj).reduce((a, b) => a + b);

  if (!data) return <LoadingSpinner />;

  return (
    <Card title={`Terminübersicht (${sumValues(data)})`} loading={loading} style={{ marginBottom: '1rem' }}>
      <Row gutter={24}>
        <Col span={6}>
          <Statistic title='Offen' value={data?.OPEN} />
        </Col>
        <Col span={6}>
          <Statistic title='Checked-in' value={data?.CHECKIN} />
        </Col>
        <Col span={6}>
          <Statistic title='Storniert' value={data?.CANCELED} />
        </Col>
        <Col span={6}>
          <Statistic title='Abgeschlossen' value={data?.CLOSED} />
        </Col>
      </Row>
    </Card>
  );
};
