import { useAuth0 } from '@auth0/auth0-react';
import {
  Input,
  Button,
  Form,
  InputNumber,
  message,
  Skeleton,
  Switch,
  Select,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import { orgaState } from 'store';
import { UpdateSettingsRequest } from 'types';
import { RefetchSettingsProps, SettingsProps } from 'types/customTypes';

const { REACT_APP_API_URL } = process.env;

const MainSettings = ({ settings, refetch }: SettingsProps & RefetchSettingsProps) => {
  const orgaDetails = useRecoilValue(orgaState);
  const { getAccessTokenSilently } = useAuth0();
  const [form] = Form.useForm();

  const saveMainSettings = useMutation(
    async (patchValues: UpdateSettingsRequest) => {
      const token = await getAccessTokenSilently();
      return axios.patch(
        `${REACT_APP_API_URL}/organizations/${orgaDetails?.id}/settings/main`,
        patchValues,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    }
  );

  const onFinish = (values: UpdateSettingsRequest) => {
    saveMainSettings.mutate(values, {
      onSuccess: () => {
        message.success('Einstellungen gespeichert.');
        refetch();
      },
    });
  };

  return (
    <>
      {settings ? (
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            description: settings?.description,
            appointmentDuration: settings?.appointmentDuration,
            appointmentLines: settings?.appointmentLines,
            bookingActive: settings?.bookingActive,
            medicalStaff: settings?.medicalStaff,
          }}
        >
          <Form.Item name='description' label='Beschreibung'>
            <Input.TextArea showCount maxLength={250} />
          </Form.Item>
          <Form.Item
            name='appointmentDuration'
            label='Termindauer'
            tooltip='Wie lange dauert ein Termin bei Ihnen?'
            extra='Termindauer in Minuten'
          >
            <Select>
              <Select.Option value={1}>1 Minute</Select.Option>
              <Select.Option value={2}>2 Minuten</Select.Option>
              <Select.Option value={3}>3 Minuten</Select.Option>
              <Select.Option value={4}>4 Minuten</Select.Option>
              <Select.Option value={5}>5 Minuten</Select.Option>
              <Select.Option value={6}>6 Minuten</Select.Option>
              <Select.Option value={10}>10 Minuten</Select.Option>
              <Select.Option value={15}>15 Minuten</Select.Option>
              <Select.Option value={20}>20 Minuten</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name='appointmentLines'
            label='Anzahl der Teststationen'
            tooltip='Wie viele Tests können Sie zur selben Uhrzeit/parallel durchführen?'
            extra='n-Buchungen, die zur selben Uhrzeit an einem Standort möglich sind.'
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item
            name='medicalStaff'
            label='Fachärztliche-Leitung'
            tooltip='Wer führt die fachärztliche Leitung?'
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='bookingActive'
            label='Nehmen Sie Terminreservierungen über Covify.de an?'
            valuePropName='checked'
          >
            <Switch checkedChildren='Ja' unCheckedChildren='Nein' />
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            loading={saveMainSettings.isLoading}
            disabled={saveMainSettings.isLoading}
          >
            Speichern
          </Button>
        </Form>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default MainSettings;
