import { useAuth0 } from '@auth0/auth0-react';
import Content from 'layout/Content';
import axios from 'axios';
import React from 'react';
import {
  QueryClient,
  QueryClientProvider,
  QueryFunctionContext,
} from 'react-query';
import DashboardLayout from 'layout/DashboardLayout';
import LoadingSpinner from 'components/LoadingSpinner';

function App() {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const defaultQueryFn = async (context: QueryFunctionContext) => {
    const token = await getAccessTokenSilently();
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/${context?.queryKey}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
      },
    },
  });

  if (isLoading) return <LoadingSpinner />;

  return (
    <QueryClientProvider client={queryClient}>
      <DashboardLayout>
        <Content />
      </DashboardLayout>
    </QueryClientProvider>
  );
}

export default App;
