import { AppointmentDTO, TestResultDTO } from 'types';
import { AppointmentStatus, TestResult } from 'types/customTypes';

export const getResultColor = (result: TestResultDTO['result']) => {
  switch (result) {
    case TestResult.Positive:
      return 'test-result__positive';
    case TestResult.Negative:
      return 'test-result__negative';
    case TestResult.Invalid:
      return 'test-result__invalid';
    default:
      break;
  }
};

export const getStatusColor = (status: AppointmentDTO['status']) => {
  switch (status) {
    case AppointmentStatus.CANCELED:
      return 'test-result__positive';
    default:
      break;
  }
};

export const getStatusText = (status: AppointmentDTO['status']) => {
  switch (status) {
    case AppointmentStatus.OPEN:
      return 'Offen';
    case AppointmentStatus.CHECKIN:
      return 'in Bearbeitung';
    case AppointmentStatus.CLOSED:
      return 'Abgeschlossen';
    case AppointmentStatus.CANCELED:
      return 'Storniert';
    default:
      break;
  }
};

export const dayOfWeek = [
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
  'Sonntag',
];

export const reasons = [
  'Besucher von Krankenhäusern, Rehabilitations- und Pflegeeinrichtungen',
  'Personal in medizinischen Einrichtungen',
];
