import React, { useState } from 'react';
import { Card } from 'antd';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { orgaState } from 'store';
import MainSettings from './MainSettings';
import TestTypeSettings from './TestTypeSettings';
import { OrganizationSettingsDTO } from 'types';
import BusinessHoursSettings from './BusinessHoursSettings';
import AbsencesSettings from './AbsencesSettings';

const DashboardSettings = () => {
  const [currentTab, setCurrentTab] = useState('main');
  const orgaDetails = useRecoilValue(orgaState);
  const { isLoading, data, refetch } = useQuery<OrganizationSettingsDTO>(
    `organizations/${orgaDetails?.id}/settings`,
    {
      enabled: orgaDetails?.id !== undefined,
    }
  );

  const tabList = [
    {
      key: 'main',
      tab: 'Allgemein',
    },
    {
      key: 'testTypes',
      tab: 'Testtypen',
    },
    {
      key: 'businessHours',
      tab: 'Öffnungszeiten',
    },
    {
      key: 'absences',
      tab: 'Abwesenheiten',
    },
  ];

  const contentList: { [key: string]: React.ReactElement } = {
    main: <MainSettings settings={data} refetch={refetch} />,
    testTypes: <TestTypeSettings settings={data} refetch={refetch} />,
    businessHours: <BusinessHoursSettings settings={data} refetch={refetch} />,
    absences: <AbsencesSettings settings={data} refetch={refetch} />,
  };

  const onTabChange = (key: string) => {
    setCurrentTab(key);
  };

  return (
    <Card
      style={{ width: '100%' }}
      title='Einstellungen'
      tabList={tabList}
      activeTabKey={currentTab}
      onTabChange={(key) => {
        onTabChange(key);
      }}
      loading={isLoading}
    >
      {contentList[currentTab]}
    </Card>
  );
};

export default DashboardSettings;
