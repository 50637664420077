import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from 'components/auth/ProtectedRoute';
import DashboardTests from 'components/dashboardTests/DashboardTests';
import Appointments from 'pages/Appointments';
import Home from 'pages/Home';
import NoMatch from 'pages/NoMatch';
import Settings from 'pages/Settings';

const Content = () => {
  return (
    <Switch>
      <ProtectedRoute exact path='/' component={Home} />
      <ProtectedRoute path='/appointments' component={Appointments} />
      <ProtectedRoute path='/tests' component={DashboardTests} />
      <ProtectedRoute path='/settings' component={Settings} />
      <Route path='*'>
        <NoMatch />
      </Route>
    </Switch>
  );
};

export default Content;
