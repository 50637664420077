import React, { FC, useEffect, useState } from 'react';
import { Layout, Menu, notification } from 'antd';
import {
  CalendarOutlined,
  ExperimentOutlined,
  HomeOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { OrganizationDTO, UserDetailsResponse } from 'types';
import { useRecoilState } from 'recoil';
import { orgaState, userState } from 'store';
import DashboardHeader from './DashboardHeader';
import { useNetwork } from 'ahooks';
import packageJson from '../../package.json';

const { Content, Sider } = Layout;

const DashboardLayout: FC = ({ children }) => {
  const [navKey, setNavKey] = useState('dashboard');
  const [collapsed, setCollapsed] = useState(false);
  const [orgaDetails, setOrgaDetails] = useRecoilState(orgaState);
  const [userDetails, setUserDetails] = useRecoilState(userState);
  const { pathname } = useLocation();
  const { online } = useNetwork();

  const { data: user } = useQuery<UserDetailsResponse>('auth/me', {
    onSuccess: (data) => setUserDetails(data),
    enabled: !!userDetails,
  });

  useQuery<OrganizationDTO>(`organizations/${user?.organizationId}`, {
    onSuccess: (data) => setOrgaDetails(data),
    enabled: !!user?.organizationId || !!orgaDetails?.id,
    retry: false,
  });

  useEffect(() => {
    if (pathname.includes('/appointments')) {
      setNavKey('appointments');
    } else if (pathname.includes('/tests')) {
      setNavKey('tests');
    } else if (pathname.includes('/settings')) {
      setNavKey('settings');
    } else {
      setNavKey('dashboard');
    }
  }, [pathname]);

  useEffect(() => {
    if (online) {
      notification.close('offline');
      notification['success']({
        message: 'Sie sind online.',
        placement: 'bottomRight',
        duration: 3,
      });
    } else {
      notification['error']({
        message: 'Netzwerkstatus: Offline',
        description:
          'Sie sind offline. Bitte überprüfen Sie Ihre Netzwerkverbindung.',
        placement: 'bottomRight',
        duration: 0,
        key: 'offline',
      });
    }
  }, [online]);

  function onCollapse() {
    setCollapsed(!collapsed);
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            color: 'white',
          }}
        >
          <div className='logo'>Covify Business</div>
          <span style={{ fontSize: '10px' }}>
            {' '}
            Version: {packageJson.version}
          </span>
        </div>
        <Menu theme='dark' mode='inline' selectedKeys={[navKey]}>
          <Menu.Item key='dashboard' icon={<HomeOutlined />}>
            <Link to='/'>Übersicht</Link>
          </Menu.Item>
          <Menu.Item key='appointments' icon={<CalendarOutlined />}>
            <Link to='/appointments'>Termine</Link>
          </Menu.Item>
          <Menu.Item key='tests' icon={<ExperimentOutlined />}>
            <Link to='/tests'>Tests</Link>
          </Menu.Item>
          <Menu.Item key='settings' icon={<SettingOutlined />}>
            <Link to='/settings'>Einstellungen</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <DashboardHeader />
        <Content style={{ margin: '0 16px' }}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
