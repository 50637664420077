import React, { useState } from 'react';
import {
  Row,
  Col,
  Form,
  Divider,
  Input,
  DatePicker,
  Space,
  TimePicker,
  Button,
  Drawer,
  message,
  Switch,
  Typography,
  Alert,
  Select,
  Radio,
  RadioChangeEvent,
} from 'antd';
import { DATE_FORMAT, TIME_FORMAT } from 'COVIFY_CONSTANTS';
import moment from 'moment';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import { QueryObserverResult, RefetchOptions, useMutation } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AppointmentDTO,
  PageAppointmentDTO,
  TestTypeDTO,
  UpdateAppointmentRequest,
} from 'types';
import ViewAppointment from './ViewAppointment';
import { useRecoilValue } from 'recoil';
import { orgaState } from 'store';
import { reasons } from 'helpers';

const { Text } = Typography;

type ViewEditProps = {
  toggle: (value?: boolean | undefined) => void;
  visible: boolean;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<PageAppointmentDTO, unknown>>;
  selectedAppointment: AppointmentDTO | undefined;
};

const ViewEditAppointmentDrawer = ({
  toggle,
  visible,
  refetch,
  selectedAppointment,
}: ViewEditProps) => {
  const [edit, setEdit] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [form] = Form.useForm();
  const orgaDetails = useRecoilValue(orgaState);

  const [selectedTest, setSelectedTest] = useState<TestTypeDTO | undefined>(
    orgaDetails.testTypes?.find(
      (t) => t.id === selectedAppointment?.testType?.referenceId
    )
  );

  const putAppointment = useMutation(
    async (booking: UpdateAppointmentRequest) => {
      const token = await getAccessTokenSilently();
      return axios.patch(
        `${process.env.REACT_APP_API_URL}/appointments`,
        booking,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    }
  );

  const onClose = () => {
    toggle(false);
    setEdit(false);
  };

  const handleUpdate = (values: UpdateAppointmentRequest) => {
    if (values?.proband !== undefined && values.startTime !== undefined) {
      values.date = moment(values.date).format(DATE_FORMAT);
      values.startTime = moment(values.startTime).format(TIME_FORMAT);
      values.id = selectedAppointment?.id;
      values.testType = orgaDetails.testTypes?.find(
        (t) => t.id === selectedTest?.id
      );
    }
    putAppointment.mutate(values, {
      onSuccess: () => {
        refetch();
        message.success('Termin erfolgreich aktualisiert.');
        onClose();
      },
      onError: () => {
        message.error(
          'Fehler beim Aktualisieren, bitte vesuchen Sie es noch einmal.'
        );
      },
    });
  };

  const handleEditView = () => {
    setSelectedTest(
      orgaDetails.testTypes?.find(
        (t) => t.id === selectedAppointment?.testType?.referenceId
      )
    );
    setEdit(!edit);
  };

  const onTestChange = (e: RadioChangeEvent) => {
    setSelectedTest(
      orgaDetails.testTypes?.find((t) => t.id === e.target.value)
    );
  };

  return (
    <div>
      <Drawer
        title={`Termin ${selectedAppointment?.id}`}
        width='33vw'
        placement='right'
        onClose={onClose}
        visible={visible}
        bodyStyle={{ marginBottom: '2rem' }}>
        <Col>
          <Space direction='horizontal'>
            <Switch
              title='Termin bearbeiten'
              checked={edit}
              checkedChildren={<EditOutlined />}
              unCheckedChildren={<EyeOutlined />}
              onChange={handleEditView}
              disabled={selectedAppointment?.status === 'CANCELED'}
            />
            <Text strong>Bearbeitungsmodus</Text>
          </Space>
          <Row>
            {edit ? (
              <Form
                form={form}
                name='editAppointment'
                onFinish={handleUpdate}
                requiredMark='optional'
                layout='vertical'
                scrollToFirstError
                initialValues={{
                  date: moment(selectedAppointment?.date, DATE_FORMAT),
                  startTime: moment(
                    selectedAppointment?.startTime,
                    TIME_FORMAT
                  ),
                  proband: {
                    firstname: selectedAppointment?.proband?.firstname,
                    lastname: selectedAppointment?.proband?.lastname,
                    birthDate: selectedAppointment?.proband?.birthDate,
                    street: selectedAppointment?.proband?.street,
                    houseNumber: selectedAppointment?.proband?.houseNumber,
                    zip: selectedAppointment?.proband?.zip,
                    city: selectedAppointment?.proband?.city,
                    country: selectedAppointment?.proband?.country,
                    email: selectedAppointment?.proband?.email,
                    phone: selectedAppointment?.proband?.phone,
                  },
                  reason: selectedAppointment?.reason,
                  testType: selectedAppointment?.testType?.referenceId,
                }}>
                <Alert
                  style={{ margin: '1rem' }}
                  showIcon
                  type='info'
                  message='Bearbeitungsfunktion aktiviert'
                  description='Vergessen Sie nicht Ihre Änderungen zu speichern.'
                />
                <Divider type='horizontal' orientation='center'>
                  Personendaten
                </Divider>
                <Form.Item
                  name={['proband', 'firstname']}
                  label='Vorname'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your firstname!',
                    },
                  ]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['proband', 'lastname']}
                  label='Nachname'
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your lastname!',
                    },
                  ]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['proband', 'birthDate']}
                  label='Geburtstag'
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Bitte Geburtsdatum (TT.MM.JJJJ) angeben!',
                    },
                    {
                      pattern: new RegExp(
                        /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/
                      ),
                      message: 'Bitte Geburtsdatum (TT.MM.JJJJ) angeben!',
                    },
                  ]}>
                  <Input type='text' placeholder='TT.MM.JJJJ' />
                </Form.Item>
                <Space>
                  <Form.Item
                    name={['proband', 'street']}
                    label='Straße'
                    rules={[
                      {
                        required: true,
                        message: 'Bitte Straße angeben!',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['proband', 'houseNumber']}
                    label='Hausnummer'
                    rules={[
                      {
                        required: true,
                        message: 'Bitte Hausnummer angeben!',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Space>
                <Space>
                  <Form.Item
                    name={['proband', 'zip']}
                    label='PLZ'
                    rules={[
                      {
                        required: true,
                        message: 'Bitte PLZ angeben!',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['proband', 'city']}
                    label='Stadt'
                    rules={[
                      {
                        required: true,
                        message: 'Bitte Stadt angeben!',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Space>
                <Form.Item
                  name={['proband', 'country']}
                  label='Land'
                  rules={[
                    {
                      required: true,
                      message: 'Bitte Land angeben!',
                    },
                  ]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['proband', 'email']}
                  label='E-mail'
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['proband', 'phone']}
                  label='Telefon'
                  rules={[
                    {
                      required: true,
                      message: 'Bitte Telefonnummer des Probanden eingeben',
                    },
                  ]}>
                  <Input style={{ width: '100%' }} />
                </Form.Item>
                <Divider type='horizontal' orientation='center'>
                  Testtyp
                </Divider>
                <Form.Item
                  name='testType'
                  rules={[
                    {
                      required: true,
                      message: 'Bitte Testtyp angeben!',
                    },
                  ]}>
                  <Radio.Group value={selectedTest?.id} onChange={onTestChange}>
                    <Space direction='vertical'>
                      {orgaDetails?.testTypes?.map((type) => (
                        <Radio key={type?.id} value={type.id}>
                          {type?.name} (
                          {type?.price === 0
                            ? 'Kostenlos'
                            : `${type?.price?.toFixed(2)} €`}
                          )
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <>
                  {selectedTest?.name === 'PoC-Antigen-Schnelltest' &&
                  selectedTest.price === 0 ? (
                    <>
                      <Form.Item
                        name='reason'
                        rules={[
                          {
                            required:
                              selectedTest?.name === 'PoC-Antigen-Schnelltest',
                            message: 'Bitte Personengruppe angeben',
                          },
                        ]}
                        extra="Wenn Person nicht zur Ausnahmegruppe gehört, bitte 'Keine Gruppe' auswählen">
                        <Select
                          options={Object.entries(reasons).map(
                            ([key, value]) => ({
                              value: value,
                              label: value,
                            })
                          )}
                        />
                      </Form.Item>
                    </>
                  ) : null}
                </>
                <Divider type='horizontal' orientation='center'>
                  Zeitpunkt
                </Divider>
                <Space>
                  <Form.Item
                    name='date'
                    label='Termin am'
                    rules={[{ required: true }]}>
                    <DatePicker format={DATE_FORMAT} />
                  </Form.Item>
                  <Form.Item
                    name='startTime'
                    label='Uhrzeit'
                    rules={[{ required: true }]}>
                    <TimePicker format={TIME_FORMAT} />
                  </Form.Item>
                </Space>

                <Row justify='center'>
                  <Button type='primary' htmlType='submit'>
                    Termin aktualisieren
                  </Button>
                </Row>
              </Form>
            ) : (
              <ViewAppointment
                selectedAppointment={selectedAppointment}
                refetch={refetch}
                onClose={onClose}
              />
            )}
          </Row>
        </Col>
      </Drawer>
    </div>
  );
};

export default ViewEditAppointmentDrawer;
