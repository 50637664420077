import React from 'react';
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useToggle } from 'ahooks';
import { QueryObserverResult, RefetchOptions, useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import { orgaState } from 'store';
import { OrganizationSettingsDTO, TestTypeDTO } from 'types';

const { REACT_APP_API_URL } = process.env;

type Props = {
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<OrganizationSettingsDTO, unknown>>;
};

const NewTestTypeDrawer = ({ refetch }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const orgaDetails = useRecoilValue(orgaState);
  const [visible, { toggle, set }] = useToggle(false);
  const [form] = Form.useForm();

  const postTestType = useMutation(
    async (values: TestTypeDTO) => {
      const token = await getAccessTokenSilently();
      return axios.put(
        `${REACT_APP_API_URL}/organizations/${orgaDetails?.id}/settings/testTypes`,
        values,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    },
    {
      onSuccess: () => {
        refetch();
        message.success('Testtyp wurde erfolgreich erstellt.');
        set(false);
        form.resetFields();
      },
      onError: () => {
        message.error(
          'Fehler beim Anlegen, bitte vesuchen Sie es noch einmal.'
        );
      },
    }
  );

  const createTestType = (values: TestTypeDTO) => {
    postTestType.mutate(values);
  };

  return (
    <>
      <Button onClick={() => set(true)} type='primary'>
        Testtyp anlegen
      </Button>
      <Drawer
        width='33vw'
        title='Neuen Testtyp anlegen'
        placement='right'
        onClose={() => set(false)}
        visible={visible}
      >
        <Col>
          <Row>
            <Form<TestTypeDTO>
              name='createTestType'
              form={form}
              layout='vertical'
              style={{ width: '100%' }}
              onFinish={createTestType}
              requiredMark='optional'
            >
              <Form.Item
                name='name'
                label='Bezeichnung'
                rules={[
                  { required: true, message: 'Bitte Bezeichnung auswählen.' },
                ]}
                extra={
                  <p>
                    Sie brauchen mehr Optionen? Bitte kontaktieren Sie uns
                    direkt per <a href='mailto:info@covify.de'>E-Mail</a>.
                  </p>
                }
              >
                <Select placeholder='Bitte wählen Sie eine Option'>
                  <Select.Option value='PoC-Antigen-Schnelltest'>
                    PoC-Antigen-Schnelltest
                  </Select.Option>
                  <Select.Option value='PCR-Test'>PCR-Test</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name='manufacturer'
                label='Hersteller'
                rules={[
                  { required: true, message: 'Bitte Hersteller eintragen.' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='description'
                label='Beschreibung'
                rules={[
                  { required: true, message: 'Bitte Beschreibung eintragen.' },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={250}
                  placeholder='Kostenloser Bürgertest - Ergebnis nach nur 15 Minunten'
                />
              </Form.Item>
              <Form.Item
                name='price'
                label='Preis in Euro (€)'
                extra='Für kostenlose Tests tragen Sie 0 ein.'
                rules={[{ required: true, message: 'Bitte Preis eintragen.' }]}
              >
                <InputNumber placeholder='19,99' min={0} decimalSeparator=',' />
              </Form.Item>
              <Row justify='center' style={{ margin: 15 }}>
                <Button type='primary' htmlType='submit'>
                  Testtyp anlegen
                </Button>
              </Row>
            </Form>
          </Row>
        </Col>
      </Drawer>
    </>
  );
};

export default NewTestTypeDrawer;
