import React from 'react';
import {
  Button,
  Card,
  DatePicker,
  message,
  Space,
  Table,
  Form,
  Row,
  Typography,
  Divider,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { BlockedTimeDTO, CreateBlockedTimeRequest } from 'types';
import { RefetchSettingsProps, SettingsProps } from 'types/customTypes';
import axios from 'axios';
import { useMutation } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilValue } from 'recoil';
import { orgaState } from 'store';
import { DATETIME_FORMAT } from 'COVIFY_CONSTANTS';
import moment from 'moment';

const { REACT_APP_API_URL } = process.env;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const AbsencesSettings = ({
  settings,
  refetch,
}: SettingsProps & RefetchSettingsProps) => {
  const { id } = useRecoilValue(orgaState);
  const { getAccessTokenSilently } = useAuth0();
  const [form] = Form.useForm();

  const columns: ColumnsType<BlockedTimeDTO> = [
    {
      title: 'Start',
      dataIndex: 'startDate',
    },
    {
      title: 'Ende',
      dataIndex: 'endDate',
    },
    {
      title: 'Aktion',
      render: (record: BlockedTimeDTO) => (
        <Button type='text' danger onClick={() => handleDelete(record?.id)}>
          Abwesenheit löschen
        </Button>
      ),
    },
  ];

  const putAbsences = useMutation(
    async (values: CreateBlockedTimeRequest) => {
      const token = await getAccessTokenSilently();
      return axios.put(
        `${REACT_APP_API_URL}/organizations/${id}/settings/block`,
        values,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    },
    {
      onSuccess: () => {
        refetch();
        message.success('Abwesenheiten gespeichert.');
      },
      onError: (error) => {
        message.error('Fehler beim Hinzufügen!');
        console.error(error);
      },
    }
  );

  const deleteAbsences = useMutation(
    async (blockedTimeId: BlockedTimeDTO['id']) => {
      const token = await getAccessTokenSilently();
      return axios.delete(
        `${REACT_APP_API_URL}/organizations/${id}/settings/block/${blockedTimeId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    },
    {
      onSuccess: () => {
        refetch();
        message.success('Abwesenheiten gelöscht.');
      },
      onError: (error) => {
        message.error('Fehler beim Löschen!');
        console.error(error);
      },
    }
  );

  const handleSubmit = (values: any) => {
    console.log(values);
    const startDate = moment(values.startEnd[0]).format(DATETIME_FORMAT);
    const endDate = moment(values.startEnd[1]).format(DATETIME_FORMAT);
    putAbsences.mutate({
      startDate,
      endDate,
    });
  };

  const handleDelete = (blockedTimeId: BlockedTimeDTO['id']) => {
    deleteAbsences.mutate(blockedTimeId);
  };

  return (
    <Card title='Abwesenheiten'>
      <Text>
        Hier können Sie Feiertage, Urlaubstage und weitere Abwensenheiten
        einpflegen.
      </Text>
      <Divider />
      <Space direction='vertical' style={{ width: '100%' }}>
        <Form
          name='absences'
          form={form}
          onFinish={handleSubmit}
          requiredMark='optional'>
          <Row justify='space-between'>
            <Form.Item
              label='Abwesenheitszeitraum'
              name='startEnd'
              rules={[{ required: true }]}>
              <RangePicker format={DATETIME_FORMAT} showTime />
            </Form.Item>
            <Button htmlType='submit' type='primary'>
              Abwesenheit hinzufügen
            </Button>
          </Row>
        </Form>
        <Table<BlockedTimeDTO>
          dataSource={settings?.blockedTimes}
          columns={columns}
          pagination={false}
        />
      </Space>
    </Card>
  );
};

export default AbsencesSettings;
