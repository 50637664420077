import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingSpinner from 'components/LoadingSpinner';

interface IProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const ProtectedRoute = ({ component, ...args }: IProtectedRouteProps) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <LoadingSpinner />,
    })}
    {...args}
  />
);

export default ProtectedRoute;
