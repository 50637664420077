import { useAuth0 } from '@auth0/auth0-react';
import {
  Drawer,
  Col,
  Row,
  Form,
  Input,
  InputNumber,
  Button,
  message,
  Select,
  Divider,
} from 'antd';
import axios from 'axios';
import React, { useEffect } from 'react';
import { QueryObserverResult, RefetchOptions, useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import { orgaState } from 'store';
import {
  CreateOrUpdateTestTypeRequest,
  OrganizationSettingsDTO,
  TestTypeDTO,
} from 'types';

const { REACT_APP_API_URL } = process.env;

type Props = {
  settings: OrganizationSettingsDTO | undefined;
  data: TestTypeDTO | undefined;
  visible: boolean;
  toggle: (value?: boolean | undefined) => void;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<OrganizationSettingsDTO, unknown>>;
};

const TestTypeUpdateDrawer = ({
  data,
  visible,
  toggle,
  refetch,
  settings,
}: Props) => {
  const [form] = Form.useForm<CreateOrUpdateTestTypeRequest>();
  const { testTypes, id } = useRecoilValue(orgaState);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        manufacturer: data?.manufacturer,
        description: data?.description,
        price: data?.price,
      });
    }
  }, [data]);

  const putTestType = useMutation(
    async (values: CreateOrUpdateTestTypeRequest) => {
      const token = await getAccessTokenSilently();
      return axios.put(
        `${REACT_APP_API_URL}/organizations/${id}/settings/testTypes`,
        values,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    },
    {
      onSuccess: () => {
        message.success('Test Typ wurde erfolgreich geändert.');
        toggle(false);
        refetch();
      },
      onError: () => {
        message.error(
          'Fehler beim Speichern, bitte vesuchen Sie es noch einmal.'
        );
      },
    }
  );

  const deleteTestType = useMutation(
    async () => {
      const token = await getAccessTokenSilently();
      return axios.delete(
        `${REACT_APP_API_URL}/organizations/${id}/settings/testTypes/${data?.id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    },
    {
      onSuccess: () => {
        refetch();
        message.success('Test Typ wurde erfolgreich entfernt.');
        toggle(false);
      },
      onError: () => {
        message.error(
          'Fehler beim Entfernen, bitte vesuchen Sie es noch einmal.'
        );
      },
    }
  );

  const onClose = () => {
    toggle(false);
  };

  const updateTestType = (values: CreateOrUpdateTestTypeRequest) => {
    values.id = data?.id;
    putTestType.mutate(values);
  };

  const handleDeleteTestType = () => {
    deleteTestType.mutate();
  };

  return (
    <Drawer
      title={`Test Typ ${data?.name}`}
      width='33vw'
      placement='right'
      onClose={onClose}
      visible={visible}
      bodyStyle={{ marginBottom: '2rem' }}>
      <Col>
        <Row>
          <Form<CreateOrUpdateTestTypeRequest>
            name='updateTestType'
            form={form}
            layout='vertical'
            style={{ width: '100%' }}
            onFinish={updateTestType}
            requiredMark='optional'>
            <Form.Item
              name='name'
              label='Bezeichnung'
              rules={[
                { required: true, message: 'Bitte Bezeichnung eintragen.' },
              ]}>
              <Select>
                {testTypes?.map((type) => {
                  if (type?.name !== undefined)
                    return (
                      <Select.Option key={type?.id} value={type?.name}>
                        {type?.name}
                      </Select.Option>
                    );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name='manufacturer'
              label='Hersteller'
              rules={[
                { required: true, message: 'Bitte Hersteller eintragen.' },
              ]}>
              <Input placeholder='Coretests' />
            </Form.Item>
            <Form.Item
              name='description'
              label='Beschreibung'
              rules={[
                { required: true, message: 'Bitte Beschreibung eintragen.' },
              ]}>
              <Input.TextArea
                showCount
                maxLength={250}
                placeholder='Kostenloser Bürgertest - Ergebnis nach nur 15 Minunten'
              />
            </Form.Item>
            <Form.Item
              name='price'
              label='Preis in Euro (€)'
              extra='Für kostenlose Tests tragen Sie 0 ein.'
              rules={[{ required: true, message: 'Bitte Preis eintragen.' }]}>
              <InputNumber placeholder='19,99' min={0} decimalSeparator=',' />
            </Form.Item>
            <Row justify='center' style={{ margin: 15 }}>
              <Button type='primary' htmlType='submit'>
                Speichern
              </Button>
            </Row>
          </Form>
        </Row>
        <Divider orientation='center'>Achtung</Divider>
        <Row justify='center' style={{ width: '100%' }}>
          <Button onClick={handleDeleteTestType} danger>
            Test Typ löschen
          </Button>
        </Row>
      </Col>
    </Drawer>
  );
};

export default TestTypeUpdateDrawer;
