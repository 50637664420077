import React from "react";
import { Card, Statistic, Row, Col } from "antd";
import LoadingSpinner from "components/LoadingSpinner";
import { TestTypeResponse } from "./DashboardOverview";

type TestTypesProps = {
  data: TestTypeResponse | undefined;
  loading: boolean;
};

export const TestTypes = ({ data, loading }: TestTypesProps) => {
  if (!data) return <LoadingSpinner />;

  return (
    <Card title={`Gebuchte Testtypen`} loading={loading} style={{ marginBottom: '1rem' }}>
      <Row gutter={24}>
        <Col span={12}>
          <Statistic title="Schnelltest" value={data?.SCHNELLTEST} />
        </Col>
        <Col span={12}>
          <Statistic title="PCR" value={data?.PCR} />
        </Col>
      </Row>
    </Card>
  );
};

