import React from 'react';
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
} from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useToggle } from 'ahooks';
import { QueryObserverResult, RefetchOptions, useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import moment from 'moment';
import { orgaState } from 'store';
import { CreateAppointmentRequest, PageAppointmentDTO } from 'types';
import { DATE_FORMAT, TIME_FORMAT } from 'COVIFY_CONSTANTS';
import { reasons } from 'helpers';

type NADProps = {
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<PageAppointmentDTO, unknown>>;
};

const NewAppointmentDrawer = ({ refetch }: NADProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const orgaDetails = useRecoilValue(orgaState);
  const [visible, { toggle, set }] = useToggle(false);
  const [form] = Form.useForm();

  const mutation = useMutation(async (booking: CreateAppointmentRequest) => {
    const token = await getAccessTokenSilently();
    return axios.post(
      `${process.env.REACT_APP_API_URL}/appointments`,
      booking,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  });

  const handleFinish = (values: CreateAppointmentRequest) => {
    if (values?.proband !== undefined && values.startTime !== undefined) {
      values.date = moment(values.date).format(DATE_FORMAT);
      const tmptime = values.startTime;
      values.startTime = moment(values.startTime).format(TIME_FORMAT);
      values.endTime = moment(tmptime).add(5, 'm').format(TIME_FORMAT);
      values.organizationId = orgaDetails?.id;
      values.appointmentType = 'MANUAL';
      values.proband.id = '';
    }
    mutation.mutate(values, {
      onSuccess: () => {
        refetch();
        form.resetFields();
        message.success('Termin erfolgreich angelegt.');
        set(false);
      },
      onError: () => {
        message.error(
          'Fehler beim Anlegen, bitte vesuchen Sie es noch einmal.'
        );
      },
    });
  };

  return (
    <>
      <Button onClick={() => set(true)} type='primary'>
        Termin anlegen
      </Button>
      <Drawer
        width='33vw'
        title='Neuen Termin anlegen'
        placement='right'
        onClose={() => set(false)}
        visible={visible}
      >
        <Form
          form={form}
          name='newAppointment'
          onFinish={handleFinish}
          requiredMark='optional'
          layout='vertical'
          scrollToFirstError
          initialValues={{
            date: moment(),
            startTime: moment(),
            privacyConsent: false,
            cwaConsent: false,
            cwaPrivacy: false,
          }}
        >
          <Divider type='horizontal' orientation='center'>
            Personendaten
          </Divider>
          <Form.Item
            name={['proband', 'firstname']}
            label='Vorname'
            rules={[
              {
                required: true,
                message: 'Please input your firstname!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['proband', 'lastname']}
            label='Nachname'
            rules={[
              {
                required: true,
                message: 'Please confirm your lastname!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['proband', 'birthDate']}
            label='Geburtstag'
            extra='Wichtig: Geburtstag muss korrekt eingetragen werden!'
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Bitte Geburtsdatum (TT.MM.JJJJ) angeben!',
              },
              {
                pattern: new RegExp(
                  /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/
                ),
                message: 'Bitte Geburtsdatum (TT.MM.JJJJ) angeben!',
              },
            ]}
          >
            <Input type='text' placeholder='TT.MM.JJJJ' />
          </Form.Item>
          <Space>
            <Form.Item
              name={['proband', 'street']}
              label='Straße'
              rules={[
                {
                  required: true,
                  message: 'Bitte Straße angeben!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['proband', 'houseNumber']}
              label='Hausnummer'
              rules={[
                {
                  required: true,
                  message: 'Bitte Hausnummer angeben!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              name={['proband', 'zip']}
              label='PLZ'
              rules={[
                {
                  required: true,
                  message: 'Bitte PLZ angeben!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['proband', 'city']}
              label='Stadt'
              rules={[
                {
                  required: true,
                  message: 'Bitte Stadt angeben!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Space>
          <Form.Item
            name={['proband', 'country']}
            label='Land'
            rules={[
              {
                required: true,
                message: 'Bitte Land angeben!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['proband', 'email']}
            label='E-mail'
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['proband', 'phone']}
            label='Telefon'
            rules={[
              {
                required: true,
                message: 'Bitte Telefonnummer des Probanden eingeben',
              },
            ]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Divider type='horizontal' orientation='center'>
            Testtyp
          </Divider>
          <Form.Item
            name='testType'
            rules={[
              {
                required: true,
                message: 'Bitte Testtyp angeben!',
              },
            ]}
          >
            <Radio.Group>
              <Space direction='vertical'>
                {orgaDetails?.testTypes?.map((type) => (
                  <Radio key={type?.id} value={type}>
                    {type?.name} (
                    {type?.price === 0
                      ? 'Kostenlos'
                      : `${type?.price?.toFixed(2)} €`}
                    )
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.testType !== curValues.testType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue(['testType', 'name']) ===
              'PoC-Antigen-Schnelltest' && getFieldValue(['testType', 'price']) === 0 ? (
                <>
                  <Divider type='horizontal' orientation='center'>
                    Personengruppe
                  </Divider>
                  <Form.Item
                    name='reason'
                    rules={[
                      {
                        required: true,
                        message: 'Bitte Personengruppe angeben',
                      },
                    ]}
                    extra="Wenn Person nicht zur Ausnahmegruppe gehört, bitte 'Keine Gruppe' auswählen"
                  >
                    <Select
                      options={Object.entries(reasons).map(([key, value]) => ({
                        value: value,
                        label: value,
                      }))}
                    />
                  </Form.Item>
                </>
              ) : null
            }
          </Form.Item>
          <Divider type='horizontal' orientation='center'>
            Datenschutzerklärung
          </Divider>
          <Form.Item
            name='privacyConsent'
            label='Zustimmung zur Datenverarbeitung'
            valuePropName='checked'
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Bitte akzeptieren Sie unsere Datenschutzerklärung um fortzufahren.'
                        )
                      ),
              },
            ]}
          >
            <Checkbox>
              Der Proband stimmt der Datenverarbeitung der von mir eingegebenen
              personenbezogenen Daten gemäß der Datenschutzerklärung zu. Die
              Reservierung ist für den Probanden kostenlos und unverbindlich.
              Sie dient nur zur Ressourcenplanung, um für den Probanden
              Wartezeiten zu reduzieren. Der Testauftrag erfolgt gesondert und
              erst vor Ort. Die Erfassung, Verarbeitung und Nutzung der Daten
              erfolgt ausschließlich zum Zwecke der Protokollierung und
              Dokumentation der Testabwicklung.
            </Checkbox>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.testType !== curValues.testType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue(['testType', 'name']) ===
              'PoC-Antigen-Schnelltest' ? (
                <>
                  <Divider type='horizontal' orientation='center'>
                    Corona-Warn-App
                  </Divider>
                  <Form.Item
                    name='cwaConsent'
                    label={
                      <Row align='middle'>
                        <img
                          height='auto'
                          width='50%'
                          src={`${process.env.PUBLIC_URL}/cwa_logo_word.svg`}
                          alt='Logo of CWA'
                        />
                        <span style={{ fontWeight: 700 }}>Corona-Warn-App</span>
                      </Row>
                    }
                    valuePropName='checked'
                  >
                    <Checkbox>
                      Der Proband möchte das Ergebnis in der Corona-Warn-App
                      angezeigt bekommen
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.cwaConsent !== curValues.cwaConsent
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue('cwaConsent') ? (
                        <Form.Item
                          name='cwaPrivacy'
                          label='Übermittlung Ihrer Daten an die Corona-Warn-App'
                          extra='Bei negativen Corona Schnelltests wird Ihr Name und Geburtsdatum in der Corona-Warn-App angezeigt. Dieser namentliche Nachweis kann z. B. beim Shoppen den Eintritt gewähren.'
                          rules={[
                            {
                              required: true,
                              message:
                                'Bitte wählen Sie eine Präferenz für die Übermittlung an die CWA-Server oder wählen Sie die CWA-Option ab.',
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio
                              value={true}
                              style={{ marginBottom: '1rem' }}
                            >
                              <strong style={{ textTransform: 'uppercase' }}>
                                Testergebnis ohne namentlichen Nachweis
                              </strong>
                              <br />
                              Hiermit erklärt der Proband sein Einverständnis
                              zum Übermitteln seines Testergebnisses und seines
                              pseudonymen Codes an das Serversystem des RKI,
                              damit der Proband sein Testergebnis mit der
                              Corona-Warn-App abrufen kann. Das Testergebnis in
                              der App kann hierbei&nbsp;
                              <strong>
                                nicht als namentlicher Testnachweis
                              </strong>{' '}
                              verwendet werden. Dem Probanden wurden Hinweise
                              zum Datenschutz ausgehändigt.
                            </Radio>
                            <Radio value={false}>
                              <strong style={{ textTransform: 'uppercase' }}>
                                Testergebnis mit namentlichen Nachweis
                              </strong>
                              <br />
                              Hiermit erklärt der Proband sein Einverständnis
                              zum Übermitteln des Testergebnisses und seines
                              pseudonymen Codes an das Serversystem des RKI,
                              damit der Proband sein Testergebnis mit der
                              Corona-Warn-App abrufen kann. Der Proband willigt
                              außerdem in die Übermittlung seines Namens und
                              Geburtsdatums an die App ein, damit sein
                              Testergebnis in der App als&nbsp;
                              <strong>namentlicher Testnachweis</strong>{' '}
                              angezeigt werden kann. Dem Probanden wurden
                              Hinweise zum Datenschutz ausgehändigt.
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                </>
              ) : null
            }
          </Form.Item>
          <Divider type='horizontal' orientation='center'>
            Zeitpunkt
          </Divider>
          <Space>
            <Form.Item name='date' label='Termin am' required>
              <DatePicker format={DATE_FORMAT} />
            </Form.Item>
            <Form.Item name='startTime' label='Uhrzeit' required>
              <TimePicker format={TIME_FORMAT} />
            </Form.Item>
          </Space>
          <Space direction='vertical' style={{ marginBottom: '2rem' }}>
            <Alert
              message='Wichtig'
              description='Manuelle Buchungen beeinflussen nicht die verfügbaren Zeiten in
              Ihrem Terminmanagement.'
              type='info'
              showIcon
            />
            <Row justify='center'>
              <Button
                type='primary'
                htmlType='submit'
                loading={mutation.isLoading}
              >
                Termin anlegen
              </Button>
            </Row>
          </Space>
        </Form>
      </Drawer>
    </>
  );
};

export default NewAppointmentDrawer;
