import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithHistory from 'components/auth/Auth0ProviderWithHistory';
import { ConfigProvider } from 'antd';
import de_DE from 'antd/lib/locale/de_DE';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'moment/locale/de';
import moment from 'moment';

moment.locale('de');

Sentry.init({
  dsn:
    'https://c655504f2b764d5daa2d2223f499200b@o583647.ingest.sentry.io/5736970',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0,
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<>An error has occured.</>}>
      <RecoilRoot>
        <Router>
          <Auth0ProviderWithHistory>
            <ConfigProvider locale={de_DE}>
              <App />
            </ConfigProvider>
          </Auth0ProviderWithHistory>
        </Router>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
