import React from "react";
import { Card, Row, Col } from "antd";
import LoadingSpinner from "components/LoadingSpinner";
import { Line, LineConfig } from "@ant-design/charts";
import { useQuery } from "react-query";
import { AppointmentCount } from "types";
import { useRecoilValue } from "recoil";
import { orgaState } from "store";

export const Forecast = () => {
  const { id } = useRecoilValue(orgaState);

  const { data, isLoading } = useQuery<AppointmentCount[]>(
    `appointments/organizations/${id}/forecast`,
    { enabled: id !== undefined }
  );

  const config: LineConfig = {
    data: data ?? [],
    xField: "date",
    yField: "count",
    tooltip: {
      customContent: (title, items): any => {
        return (
          <div>
            <h5 style={{ marginTop: 16, fontSize: 12 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { value, color } = item;
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{
                      marginBottom: 4,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{ backgroundColor: color }}
                    ></span>
                    <span
                      style={{
                        display: "inline-flex",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ marginRight: 16 }}>Termine:</span>
                      <span className="g2-tooltip-list-item-value">
                        <strong>{value}</strong>
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      },
    },
    point: {
      size: 5,
      shape: "diamond",
    },
    label: {
      style: {
        fill: "#aaa",
      },
    },
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <Card
      title="7-Tage-Vorschau"
      loading={isLoading}
      style={{ marginBottom: "1rem" }}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Line {...config} />
        </Col>
      </Row>
    </Card>
  );
};
