import React, { useEffect, useState } from "react";
import {
  Table,
  Typography,
  Card,
  Button,
  Space,
  DatePicker,
  message,
  TablePaginationConfig,
  Input,
} from "antd";
import { AppointmentDTO, PageAppointmentDTO } from "types";
import { useMutation, useQuery } from "react-query";
import { SyncOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { useToggle } from "ahooks";
import moment from "moment";
import { orgaState } from "store";
import NewAppointmentDrawer from "./NewAppointmentDrawer";
import { DATE_FORMAT } from "COVIFY_CONSTANTS";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { ColumnsType, FilterValue } from "antd/lib/table/interface";
import ViewEditAppointmentDrawer from "./ViewEditAppointmentDrawer";
import { getStatusColor, getStatusText } from "helpers";

const { Text } = Typography;
const { Search } = Input;

const DashboardAppointments = () => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 20,
    hideOnSinglePage: true,
  });
  const [sortState, setSortState] = useState<"asc" | "desc">("asc");
  const [queryState, setQueryState] = useState<string>("");
  const [statusFilters, setStatusFilters] = useState<FilterValue>(["OPEN"]);
  const [selectedAppointment, setAppointment] = useState<AppointmentDTO>();
  const [randomKey, setRandomKey] = useState(Date.now());
  const [selectedDate, setSelectedDate] = useState(moment());
  const orgaDetails = useRecoilValue(orgaState);
  const { getAccessTokenSilently } = useAuth0();

  const [visible, { toggle, set }] = useToggle(false);

  const { isLoading, data, refetch, isFetching } = useQuery<PageAppointmentDTO>(
    `appointments/organizations/${orgaDetails?.id}?date=${selectedDate.format(
      DATE_FORMAT
    )}&status=${statusFilters.join(",")}&sort=startTime,${sortState}&page=${
      pagination.current && pagination?.current - 1
    }&size=${pagination.pageSize}${queryState && `&query=${queryState}`}`,

    {
      enabled: !!orgaDetails?.id,
      onSuccess: (data) =>
        setPagination({ ...pagination, total: data?.totalElements }),
    }
  );

  const checkIn = useMutation(
    async (id: AppointmentDTO["id"]) => {
      const token = await getAccessTokenSilently();
      return axios.patch(
        `${process.env.REACT_APP_API_URL}/appointments/${id}/status`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
    },
    {
      onSuccess: () => {
        message.success("Check-in erfolgreich.");
        refetch();
      },
      onError: () => {
        message.error(
          "Check-in fehlgeschlagen, bitte versuchen Sie es noch einmal."
        );
      },
    }
  );

  useEffect(() => {
    if (orgaDetails?.id) refetch();
  }, [selectedDate, refetch, orgaDetails?.id]);

  const handleDateChange = (
    value: moment.Moment | null,
    dateString: string
  ) => {
    value && setSelectedDate(value);
  };

  const handleClick = (key: AppointmentDTO["id"]) => {
    const filterSelected = data?.content?.filter((value) => value?.id === key);
    filterSelected && setAppointment(filterSelected[0]);
    set(true);
  };

  useEffect(() => {
    if (!visible) {
      setAppointment(undefined);
      setRandomKey(Date.now());
    }
  }, [visible]);

  const handleCheckIn = (id: AppointmentDTO["id"]) => {
    checkIn.mutate(id);
  };

  const handleSearch = (query: string) => {
    setQueryState(query);
    setPagination({ ...pagination, current: 1 });
    refetch();
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    setPagination({ ...pagination, current: pagination.current });
    if (sorter.order === "ascend") {
      setSortState("asc");
    } else if (sorter.order === "descend") {
      setSortState("desc");
    }
    if (filters["status"] === null) {
      setStatusFilters(["OPEN"]);
    } else {
      setStatusFilters(filters["status"]);
    }
    refetch();
  };

  const columns: ColumnsType<AppointmentDTO> = [
    {
      title: "Name",
      render: (record: AppointmentDTO) => (
        <Text>
          {record?.proband?.firstname} {record?.proband?.lastname}
        </Text>
      ),
    },
    {
      title: "Geburtstag",
      dataIndex: ["proband", "birthDate"],
    },
    {
      title: "E-Mail",
      dataIndex: ["proband", "email"],
    },
    {
      title: "Termin",
      sorter: true,
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
      render: (record: AppointmentDTO) => (
        <Text>
          {record?.date} - {record?.startTime}
        </Text>
      ),
    },
    {
      title: "Test",
      render: (record: AppointmentDTO) => (
        <Text>
          {record?.testType?.name} (
          {record?.testType?.price === 0
            ? "Kostenlos"
            : `${record?.testType?.price?.toFixed(2)} €`}
          )
        </Text>
      ),
    },
    {
      title: "Status",
      dataIndex: ["status"],
      filteredValue: statusFilters,
      filters: [
        {
          text: "Offen",
          value: "OPEN",
        },
        {
          text: "Storniert",
          value: "CANCELED",
        },
      ],
      render: (status: AppointmentDTO["status"]) => (
        <Text className={getStatusColor(status)}>{getStatusText(status)}</Text>
      ),
    },
    {
      title: "Aktion",
      render: (record: AppointmentDTO) => (
        <Button
          disabled={checkIn.isLoading || record.status === "CANCELED"}
          onClick={() => handleCheckIn(record?.id)}
        >
          Check-in
        </Button>
      ),
    },
  ];

  return (
    <>
      <Card
        title="Termine"
        extra={
          <Space>
            <Search
              placeholder="Nach Namen suchen"
              onSearch={handleSearch}
              loading={isLoading}
              enterButton
              allowClear
            />
            <DatePicker
              format={DATE_FORMAT}
              value={selectedDate}
              onChange={handleDateChange}
            />
            <Button
              loading={isFetching}
              onClick={() => refetch()}
              type="default"
              icon={<SyncOutlined />}
            />
            <NewAppointmentDrawer refetch={refetch} />
          </Space>
        }
      >
        <Table<AppointmentDTO>
          columns={columns}
          loading={isLoading}
          dataSource={data?.content}
          pagination={pagination}
          onChange={handleTableChange}
          rowClassName="row-pointer"
          rowKey="id"
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: () => handleClick(record.id),
            };
          }}
        />
      </Card>
      <ViewEditAppointmentDrawer
        key={randomKey}
        toggle={toggle}
        visible={visible}
        refetch={refetch}
        selectedAppointment={selectedAppointment}
      />
    </>
  );
};

export default DashboardAppointments;
